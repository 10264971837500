import React from 'react'
import { Carousel } from 'react-bootstrap'
import PropTypes from 'prop-types'
import 'components/Slider/slider.css'


import {Div, Img, StyledSlid, StyledSlaidInner, StyledSlaidInnerKinder,ImgSmall, ImgSmall2, ImgHelp, H1, P, A, Button, Div2,
Links, H1inLine, StyledMobileH1, StyledKinderH1, ImgEbook, StyledSlaidInnerEbook, ImgSlider, StyledSlaidInnerHelp, StyledSlaidInnerShop, Plast } from './StyleSlider';

const foto = [
  { id: 'Warszawa', banerSmall: 'https://old.wydawnictwokatechetyczne.pl/assets/slider/baner_warszawa_2025.png', banerBig: 'https://old.wydawnictwokatechetyczne.pl/assets/slider/baner-warszawa2024.png', buttonNews:'https://wydawnictwokatechetyczne.pl/podreczniki/szkola-podstawowa-klasy-1-4/#4'},
  { id: 'Warmia', banerSmall: 'https://old.wydawnictwokatechetyczne.pl/assets/slider/baner_warmia_2025.png', banerBig:'https://old.wydawnictwokatechetyczne.pl/assets/slider/baner-warmia2024.png', buttonNews:'https://warmia.wydawnictwokatechetyczne.pl/podreczniki/szkola-podstawowa-klasy-1-4/#4' },
  { id: 'Płock', banerSmall: 'https://old.wydawnictwokatechetyczne.pl/assets/slider/baner_plock_2025.png', banerBig:'https://old.wydawnictwokatechetyczne.pl/assets/slider/baner-plock2024.png', buttonNews:'https://plock.wydawnictwokatechetyczne.pl/podreczniki/szkola-podstawowa-klasy-1-4/#4' },
];
const Slider = ({ openModalSliderFn, openModalNewsFn }) => (
  <Div>
    {/*<Carousel interval={null}>*/}
      <Carousel >
    <Carousel.Item>
        <Img
          src="https://old.wydawnictwokatechetyczne.pl/assets/baner-nowosci.jpg"
          alt="baner-nowosci"
        />
        <Carousel.Caption className="ala">
          <StyledSlid >
            <ImgSmall
              src={foto.find(foto => foto.id === process.env.GATSBY_CITY).banerSmall}
              alt="baner_warszawa"
            />
            <StyledSlaidInner isSmaller isLeft>
              <H1>NOWOŚCI</H1>
              <P>
                Nowe podręczniki do klas IV i VIII szkoły podstawowej oraz klasy IV liceum czteroletniego oraz klasy V technikum pięcioletniego
              </P>
              <Links
                className="button light-blue"
                to={foto.find(foto => foto.id === process.env.GATSBY_CITY).buttonNews}
              >
                Zobacz więcej
              </Links>
              {/*<Button*/}
              {/*  className="button light-blue"*/}
              {/*  onClick={openModalSliderFn}*/}
              {/*  type="button"*/}
              {/*>*/}
              {/*  Zobacz prezentacje*/}
              {/*</Button>*/}
            </StyledSlaidInner>
          </StyledSlid>
        </Carousel.Caption>
      </Carousel.Item>

      {/*   second Slide*/}

      <Carousel.Item>
        <Img
          src="https://old.wydawnictwokatechetyczne.pl/assets/baner-nowosci.jpg"
          alt="baner-nowosci"
        />
        <Carousel.Caption className="ala">
          <StyledSlid>
            <ImgSmall2
              src="https://old.wydawnictwokatechetyczne.pl/assets/baner-przedszkole.png"
              alt="a"
            />
            <StyledSlaidInnerKinder>
              <StyledMobileH1 isTop>
                Podręczniki dla przedszkolaków
              </StyledMobileH1>
              <StyledKinderH1 isTop>Przedszkole</StyledKinderH1>
              <P>Podręczniki dla przedszkolaków</P>
              <Links
                className="button light-blue"
                to="/podreczniki/przedszkole/"
              >
                Zobacz więcej
              </Links>
            </StyledSlaidInnerKinder>
          </StyledSlid>
        </Carousel.Caption>
      </Carousel.Item>

      {/*third Slide*/}

      <Carousel.Item >
        <ImgSlider
          src={foto.find(foto => foto.id === process.env.GATSBY_CITY).banerBig}
          alt="Baner all books"
        />
      </Carousel.Item>

      <Carousel.Item>
        <Img
          src="https://old.wydawnictwokatechetyczne.pl/assets/baner-nowosci.jpg"
          alt="baner-nowosci"
        />
        <Carousel.Caption className="ala">
          <StyledSlid>
            <ImgEbook
              src="https://old.wydawnictwokatechetyczne.pl/assets/baner-multibooki_22.png"
              alt="a"
            />
            <StyledSlaidInnerEbook>
              <div>
                <H1inLine>E-podręczniki<br/> i Multibooki</H1inLine>

              </div>

              <A as="button"
                isTop
                className="button light-blue"
                onClick={openModalNewsFn}
                role="button"
              >
                Zobacz prezentację
              </A>

            </StyledSlaidInnerEbook>
          </StyledSlid>
        </Carousel.Caption>
      </Carousel.Item>
      {/*fivef Slide*/}
      <Carousel.Item>
        <Img
          src="https://old.wydawnictwokatechetyczne.pl/assets/tlo-multimedia.jpg"
          alt="Baner pomoce"
        />
        <Carousel.Caption className="ala">
          <StyledSlid isBig>
            <ImgHelp
              small
              isLeft
              src="https://old.wydawnictwokatechetyczne.pl/assets/pomoce.png"
              alt="a"
            />
            <StyledSlaidInnerHelp>
              <div><H1inLine key={2}>Multimedialne pomoce <br/> do katechezy</H1inLine></div>

              <A
                className="button light-blue"
                href="https://portalkatechetyczny.pl/"
                role="button"
              >
                Przejdź do portalu
              </A>
            </StyledSlaidInnerHelp>
          </StyledSlid>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <Div2 />
        <Carousel.Caption className="ala">
          <StyledSlaidInnerShop>
            <H1 isBig>SKLEP INTERNETOWY</H1>
            <Plast>
              Podręczniki do nauki religii dla pięciolatków i sześciolatków,
              uczniów szkół podstawowych i ponadpodstawowych oraz poradniki
              metodyczne dla nauczycieli
            </Plast>
            <A
              isToper
              isRight
              className="button light-blue"
              href="https://sklep.wydawnictwokatechetyczne.pl/"
              role="button"
            >
              Kup teraz
            </A>
          </StyledSlaidInnerShop>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  </Div>
)
Slider.propTypes = {
  openModalSliderFn: PropTypes.func,
  openModalNewsFn: PropTypes.func,
}


Slider.defaultProps = {
  openModalSliderFn: false,
  openModalNewsFn: false
}
export default Slider
