import React, {useState, useEffect,} from "react";
import styled, { css } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

const StyleWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ theme, second }) => second ? theme.none : theme.body};  
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
 
    padding-bottom: ${({second}) => second ?  "0" : "30px" };
    ${({ notShow }) =>
            notShow &&
            css`
      padding: 0;
    `}
`;

const StyledNews = styled.div`
  width: 90%;
  height: auto;  
  ${({ theme }) => theme.media.desktop} {
    width: 70%;
    height: auto;
    max-width: 1200px;
    margin-top: 10px;
  }

  
  ${({ borderRed }) =>
          borderRed &&
    css`
      padding: 15px 30px;
      border:5px solid red;
        color:red;
      box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
        background-color: ${({ theme }) => theme.white};
      border-radius: 15px;
      margin-bottom: 10px;
    `}

  ${({ mode }) => {
      switch (mode) {
          case "red":
              return css`
                  background-color: red;
                  opacity: 0.8;
                  border-radius: 15px;
                  padding: 15px 30px;
                  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
        `;
          case "white":
              return css`
                  background-color: ${({ theme }) => theme.white};
                  opacity: 0.8;
                  border-radius: 15px;
                  padding: 15px 30px;
                  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
          `;
          default:
              return css`
          color: ${({ theme }) => theme.blue};        
        `;
      }
  }} ;
    
`;
const StyledTitle = styled.div`
  width: 100%;
  max-width: 1220px;
  margin: 10px 0;
`;

const TitleH1 = styled.h1`
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  color: ${({ theme }) => theme.blue};
  border-left: 4px solid ${({ theme }) => theme.green};
  padding-left: 12px;
  margin: 15px 0 5px 15px;
  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.size.l};
    font-weight: 600;
    font-family: ${({ theme }) => theme.font.family.Montserrat};
    color: ${({ theme }) => theme.blue};
    border-left: 4px solid ${({ theme }) => theme.green};
    padding-left: 12px;
    margin: 20px 0 10px 15px;
  }
`;

const Img = styled.img`

  width: ${({Small}) => Small ?  "130px" : "170px" };
  height: ${({Small}) => Small ?  "180px" : "240px" };
  display: flex;
  flex: 1;
  margin-bottom: 15px ;
  ${({ theme }) => theme.media.tablet} {
    width: ${({Small}) => Small ?  "130px" : "170px" };
    height: ${({Small}) => Small ?  "180px" : "240px" };
    margin-right: 1em;

  }
  ${({ theme }) => theme.media.desktop} {
    margin-right: 5px;
    /*width: 120px;*/
    width: ${({Small}) => Small ?  "135px" : "170px" };  
    height: ${({Small}) => Small ?  "190px" : "240px" };
    z-index: 1;
    padding: 10px;

    &:hover {
      display: block;
      opacity: 0.5;
   
    }
  }
`;
const Img2 = styled.img`
  display: none;
  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
  ${({ theme }) => theme.media.desktop} {
    width: ${({Small}) => Small ?  "115px" : "150px" };
    height: ${({Small}) => Small ?  "170px" : "220px" };
    padding: 15% 15%;
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0.3);
    object-fit: scale-down;
    object-position: 50% 50%;
    z-index: 1;
    &:hover {
      display: block;
      opacity: 1;
    }
  }
 
`;

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xm};
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  color: ${({ theme }) => theme.blue};
  margin: 15px 0 5px 0;
  
  ${({ borderRed }) =>
          borderRed &&
          css`
      color: white;
    `}
`;


const StyledH1 = styled(H1)`
  ${({mode}) => {
  switch (mode) {
    case "black":
      return css`        
          color: ${({ theme }) => theme.blackfont};           
        `;
      case "white":
          return css`
              color: ${({ theme }) => theme.white};
          `;
    default:
      return css`
          color: ${({ theme }) => theme.blue};        
        `;
  }
}}
`;

const StyledP = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  margin: 0.5em 0 0.5em 0;
  font-weight: 400;
  line-height: 1.6;
    
p{
  a{
          text-decoration: none;}
}

    ${({ mode }) => {
        switch (mode) {
            case "black":
                return css`
                    font-size: ${({ theme }) => theme.font.size.s};
                    margin-top: ${({displayFlex}) => displayFlex ? "-10px;" : "0"};                
                    overflow: hidden;
                    word-wrap: unset;
                    font-weight: 500;
                    line-height: 1.7;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    a{
                        color:white;
                        font-weight: bold;
                    }          
        `;
            case "white":
                return css`
                    font-size: ${({ theme }) => theme.font.size.s};                  
                    margin: 5px 0;
                    overflow: hidden;
                    word-wrap: unset;
                    font-weight: 500;
                    line-height: 1.7;
                    color: white;
                    a{
                        color:white;
                        font-weight: bold;
                    }
          `;
            default:
                return css`                    
                    margin: 5px 0;
                    overflow: hidden;
                    word-wrap: unset; 
                    color: ${({ theme }) => theme.blackfont};
                    a{
                        color: ${({ theme }) => theme.blackfont};
                        font-weight: bold;
                    }        
        `;
        }
    }} `;



const StyledData = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column; 
  padding: 0;
  margin: 0;
    ${({ theme }) => theme.media.desktop} {
        flex-direction: ${({displayFlex}) => displayFlex ? "row" : "column"};
        gap: ${({displayFlex}) => displayFlex ? "20px" : 0};        
    }
`;
const Images = styled.div`
  padding: 10px 0;
display: flex;
  flex-direction: column;
  align-items:center;
justify-content: center;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
  }

 ${({ theme }) => theme.media.desktop} {
   flex-direction: row;
  margin-left:${({displayFlex}) => displayFlex ? "5px" : "5%"};
   padding: 10px 0;
  justify-content: flex-start;
 }

`;
const BoxPhotos = styled.a`
  width: auto;
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const P = styled.p`
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: ${({ theme }) => theme.font.size.xxxs};
  font-family: ${({ theme }) => theme.font.family.Montserrat};

`;
const StyledButton = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;
`;
const Button = styled.button`
  width: 300px;
  height: 45px;
  border-radius: 8px;
  font-size: ${({ theme }) => theme.font.size.s};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  border: none;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
  background-color: white;
  font-weight: 600;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba(252, 246, 227, 0.8);
    font-size: ${({ theme }) => theme.font.size.xxxm};
  }
`;

const News = ({toogleModalImagesOpen }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsNews(sort: { fields: sort }) {
        nodes {
          id
          colortitle
          texthtmlcolor
           backgroundcolor
           displaycolumnorrow
          borderred
          notshow
          title
          description
          buttontext
          nextdata {
           ... on DatoCmsHeadtitletml {
              id
              headlinetxt
            }
            ... on DatoCmsTexthtml {
              id
              texthtml
            }
            ... on DatoCmsImgafew {
              id
              imgsdata {
                title
                filename
                customData
                alt
              }
            }
          }
        }
      }
    }
  `);


  return (
    <StyleWrapper>
      <StyledTitle>
        <TitleH1>AKTUALNOŚCI</TitleH1>
      </StyledTitle>
      {data.allDatoCmsNews.nodes.map((New) => (
        <StyleWrapper key={New.id} notShow={New.notshow} second>
          {!New.notshow&&<StyledNews  mode={New.backgroundcolor} borderRed={New.borderred}>

          {New.title ? (<StyledData>
            <StyledH1 mode={New.colortitle}  >{New.title}</StyledH1>
          </StyledData>): ''}
          {New.description ? (
          <StyledP >{New.description}</StyledP>): null}

          <StyledData displayFlex={New.displaycolumnorrow}>

            {New.nextdata.map((item) => {

              const itemKey = Object.keys(item)[1];

              switch (itemKey) {
                case "headlinetxt":
                  return (
                    <H1
                      key={item.id}
                      dangerouslySetInnerHTML={{ __html: item.headlinetxt,
                      }}
                    />
                  );
                case "texthtml":
                  return (
                    <StyledP
                      displayFlex={New.displaycolumnorrow}
                      mode={New.texthtmlcolor}
                      key={item.id}
                      dangerouslySetInnerHTML={{ __html: item.texthtml }}
                    />
                  );
                case "imgsdata":
                  // eslint-disable-next-line no-case-declarations
                  const foto = item.imgsdata.map((i) => (
                    <BoxPhotos key={i.filename} target="_blank" alt={i.alt} href={i.alt}>
                    <Img src={i.customData.ownUrl} alt={i.customData.Small} Small={i.customData.Small} />
                      <Img2
                        Small={i.customData.Small}
                        src="https://old.wydawnictwokatechetyczne.pl/assets/eye_icon.png"
                        alt="oko"
                      />
                      <P>{i.title}</P>
                    </BoxPhotos>
                  ));
                  return <Images key={item.id} displayFlex={New.displaycolumnorrow}>{foto}</Images>
                default:
                  return null;
              }
            })}

            {New.buttontext ? (
              <StyledButton>
                <Button >{New.buttontext}</Button>
              </StyledButton>
            ): null}

          </StyledData>
        </StyledNews>}
          </StyleWrapper>
      ))}
    </StyleWrapper>
  );
};

export default News;
